import { Component } from "react";
import styled from "styled-components";

// Utils
import { Color, responsive, rem } from "../../utils/style";
import { trackCheckoutCTAClicked } from "../../utils/tracking/cart";

// Components
import AsyncRitualButton from "../global/AsyncRitualButton";
import Text from "../Text";

// Store
import { connect } from "react-redux";
import { reserveInput } from "../../store/apply-code/actions";

const BottomNav = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  background: ${Color.white};
  z-index: 999;
  background-color: #ffffff;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;

  ${responsive.sm`
    display: none;
  `}
`;

const BottomCheckoutButtonContainer = styled.div`
  width: 100%;
  margin-top: 12px;
  margin-bottom: calc(12px + env(safe-area-inset-bottom));
  margin-left: 20px;
  margin-right: 20px;
`;

const BottomCheckoutButton = styled(AsyncRitualButton)`
  width: 100%;
  height: 40px;

  span {
    font-size: ${rem(14)};
    line-height: ${rem(24)};
  }
`;

export class CartNav extends Component {
  constructor(props) {
    super(props);
    this._handleCheckoutCTAClicked = this._handleCheckoutCTAClicked.bind(this);
  }

  async _handleCheckoutCTAClicked() {
    await trackCheckoutCTAClicked();
    await this.props.dispatchReserveInput();
  }

  render() {
    const { checkoutUrl, isProcessing } = this.props;

    return (
      <div>
        <BottomNav>
          <BottomCheckoutButtonContainer>
            <BottomCheckoutButton
              disabled={isProcessing}
              target="_self"
              onClick={this._handleCheckoutCTAClicked}
              href={checkoutUrl}
            >
              <Text
                id="cart.nav.button-checkout"
                defaultMessage="Proceed to Checkout"
              />
            </BottomCheckoutButton>
          </BottomCheckoutButtonContainer>
        </BottomNav>
      </div>
    );
  }
}

export default connect(null, {
  dispatchReserveInput: reserveInput,
})(CartNav);
