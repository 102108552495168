import styled from "styled-components";

// Services
import cartService from "../../services/cart";

// Utils
import metrics from "../../utils/metrics";
import {
  getPlanIdForProductSku,
  getProductAttributes,
} from "../../utils/planToProduct";
import { responsive } from "../../utils/style";

// Components
import QuickAddCard from "../QuickAddCard";
import QuickAddCardWide from "../QuickAddCardWide";
import ScrollableOverflow from "../ScrollableOverflow";

import { useSelector } from "react-redux";
import { bestEligiblePromotion } from "../../store/promotion/selectors";

// Styled Elements
const UpsellContentWrapper = styled.div`
  width: 100%;
  display: flex;

  margin-bottom: 16px;

  ${responsive.sm`
    align-items: center;
  `}

  ${responsive.md`
    margin-bottom: 32px;
  `}
`;

const UpsellContent = styled.div`
  width: 100%;
  display: flex;

  ${responsive.sm`
    flex-direction: ${(p) => (p.simplified ? "column" : "row")};
    align-items: ${(p) => (p.simplified ? "center" : "unset")};
  `}
`;

const QuickAddCardWideWrapper = styled.div`
  margin: 0 16px 0 0;

  &:last-of-type {
    margin-right: 0;
  }

  ${responsive.sm`
    margin: 0 0 16px 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  `}
`;

const QuickAddCardWrapper = styled.div`
  margin: 0 16px 0 0;
  flex: 1 0 calc(33.333% - (32px / 3));

  &:last-of-type {
    margin-right: 0;
  }
`;

export default function UpsellItems({
  isProcessing,
  upsellSkus,
  cartQuantity,
  contentfulProducts,
  newYearsPromoEnabled,
}) {
  const promotion = useSelector(bestEligiblePromotion);

  function handleCTAClick(productName) {
    metrics.track("CTA Clicked", {
      location: "Cart Upsell",
      title: productName,
    });
  }

  function renderSimplifiedUpsellItem(plan, isProcessing, upsellOnClick) {
    return (
      <QuickAddCardWideWrapper key={plan.planId}>
        <QuickAddCardWide
          planId={plan.planId}
          disabled={isProcessing}
          onClick={upsellOnClick}
          showStrikethrough={true}
          blueButton={true}
          simplified={true}
          isLink={false}
          location="Cart Upsell"
          promotion={promotion}
        />
      </QuickAddCardWideWrapper>
    );
  }

  function renderUpsellItem({
    planId,
    isProcessing,
    upsellOnClick,
    showStrikethrough,
  }) {
    return (
      <QuickAddCardWrapper key={planId}>
        <QuickAddCard
          planId={planId}
          disabled={isProcessing}
          onClick={upsellOnClick}
          showStrikethrough={true}
          blueButton={false}
          location="Cart Upsell"
          promotion={promotion}
        />
      </QuickAddCardWrapper>
    );
  }

  function contentfulProductStockStatus(sku) {
    const product = contentfulProducts.filter((product) => product.sku === sku);
    if (product.length > 0) {
      return product[0].stockStatus;
    }
    return null;
  }

  function getInStockSkus(skus) {
    const skuList = skus;

    // Only allow products that are in stock to be added to upsell
    let inStockSkus = skuList.reduce((result, sku) => {
      const productStockStatus = contentfulProductStockStatus(sku);
      if (productStockStatus === "in stock") {
        result.push(sku);
      } else {
        console.warn(`${sku} is out of stock - removing from Upsell`);
      }
      return result;
    }, []);

    return inStockSkus;
  }

  const simplified = cartService.isSimplified;
  let inStockSkus = getInStockSkus(upsellSkus);

  if (!inStockSkus || !inStockSkus.length) return null;

  const upsellCards = inStockSkus.map((sku) => {
    const planId = getPlanIdForProductSku(sku);
    if (!planId) return null;

    const attributes = getProductAttributes(planId);
    const upsellOnClick = handleCTAClick.bind(this, attributes.name);
    const showStrikethrough = cartQuantity === 1 || newYearsPromoEnabled;

    return simplified
      ? renderSimplifiedUpsellItem({
          planId,
          isProcessing,
          upsellOnClick,
          showStrikethrough,
        })
      : renderUpsellItem({
          planId,
          isProcessing,
          upsellOnClick,
          showStrikethrough,
        });
  });

  // The S and M breakpoint render one fewer card.
  const shortedUpsellCards = [...upsellCards];
  shortedUpsellCards.pop();
  return (
    <>
      {/* The upsell content should be scrollable on XS breakpoints */}
      <UpsellContentWrapper className="d-flex d-sm-none">
        <ScrollableOverflow centered={false} bleedLeft={true} bleedRight={true}>
          <UpsellContent simplified={simplified}>{upsellCards}</UpsellContent>
        </ScrollableOverflow>
      </UpsellContentWrapper>

      {/**
       * For S and M breakpoints, only two cards are renderd. We don't
       * render the DraggalbeOverflow component as the UpsellContent has a
       * dynamic width, and expands to fill its container.
       */}
      <UpsellContentWrapper className="d-none d-sm-flex d-lg-none">
        <UpsellContent simplified={simplified}>
          {simplified ? upsellCards : shortedUpsellCards}
        </UpsellContent>
      </UpsellContentWrapper>

      {/**
       * For L breakpoints, all three upsell cards are renderd. We don't
       * render the DraggalbeOverflow component as the UpsellContent has a
       * dynamic width, and expands to fill its container
       */}
      <UpsellContentWrapper className="d-none d-lg-flex">
        <UpsellContent simplified={simplified}>{upsellCards}</UpsellContent>
      </UpsellContentWrapper>
    </>
  );
}
