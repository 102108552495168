import { Component } from "react";
import { connect } from "react-redux";
import cartProductSelectors from "../../store/cart-product/selectors";
import {
  updateLimitBanner,
  updateProductOfferLimitBanner,
  updateReplaceBanner,
  updateWipeoutBanner,
} from "../../store/cart/actions";
import cartSelectors from "../../store/cart/selectors";
import subscriptionSelectors from "../../store/subscription/selectors";
import userSelectors from "../../store/user/selectors";
import { trackCartViewed } from "../../utils/tracking/cart";
import { getCheckoutUrlWithoutPlan } from "../../utils/urlBuilder";
import CartNav from "./CartNav";
import EmptyCart from "./EmptyCart";
import ReviewOrder from "./ReviewOrder";

const classPage = class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cartViewedEventFired: false,
    };
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Cart",
    });

    this._cartViewed();
  }

  componentWillUnmount() {
    const {
      dispatchUpdateReplaceBanner,
      dispatchUpdateLimitBanner,
      dispatchUpdateWipeoutBanner,
      dispatchUpdateProductOfferLimitBanner,
    } = this.props;
    dispatchUpdateReplaceBanner(false /* shouldDisplay */);
    dispatchUpdateLimitBanner(false);
    dispatchUpdateWipeoutBanner(false);
    dispatchUpdateProductOfferLimitBanner(false);
  }

  componentDidUpdate() {
    this._cartViewed();
  }

  _cartViewed() {
    const { activeCart, isProcessing } = this.props;
    if (activeCart && !isProcessing && !this.state.cartViewedEventFired) {
      trackCartViewed();
      this.setState({
        cartViewedEventFired: true,
      });
    }
  }

  render() {
    const checkoutUrl = getCheckoutUrlWithoutPlan();

    const {
      activeCart,
      activeCartProducts,
      contentfulProducts,
      cartQuantity,
      cartProductTotal,
      isOneTimeOnly,
      shouldShowLimitBanner,
      shouldShowWipeoutBanner,
      isProcessing,
      isLoggedIn,
      hasActiveSubscription,
    } = this.props;

    if (!activeCartProducts.length) {
      return <EmptyCart hasActiveSubscription={hasActiveSubscription} />;
    }

    return (
      <>
        <CartNav checkoutUrl={checkoutUrl} isProcessing={isProcessing} />
        <ReviewOrder
          cart={activeCart}
          cartProducts={activeCartProducts}
          cartQuantity={cartQuantity}
          cartProductTotal={cartProductTotal}
          isOneTimeOnly={isOneTimeOnly}
          contentfulProducts={contentfulProducts}
          shouldShowLimitBanner={shouldShowLimitBanner}
          shouldShowWipeoutBanner={shouldShowWipeoutBanner}
          checkoutUrl={checkoutUrl}
          isProcessing={isProcessing}
          isLoggedIn={isLoggedIn}
          hasActiveSubscription={hasActiveSubscription}
        />
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    activeCart: cartSelectors.activeCart(state),
    activeCartProducts: cartProductSelectors.sortedActiveCartProducts(state),
    cartQuantity: cartProductSelectors.activeCartProductQuantity(state),
    cartProductTotal: cartProductSelectors.activeCartProductTotal(state),
    isOneTimeOnly: cartProductSelectors.activeCartOneTimeOnly(state),
    isProcessing: cartSelectors.isProcessing(state),
    shouldShowLimitBanner: cartSelectors.showLimitBanner(state),
    shouldShowWipeoutBanner: cartSelectors.showWipeoutBanner(state),
    isLoggedIn: userSelectors.isLoggedIn(state),
    hasActiveSubscription: subscriptionSelectors.hasActiveSubscription(state),
  };
};

export default connect(mapStateToProps, () => ({
  dispatchUpdateReplaceBanner: updateReplaceBanner,
  dispatchUpdateLimitBanner: updateLimitBanner,
  dispatchUpdateWipeoutBanner: updateWipeoutBanner,
  dispatchUpdateProductOfferLimitBanner: updateProductOfferLimitBanner,
}))(classPage);
