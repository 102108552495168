import styled from "styled-components";

// Constants
import { ActionType } from "../../constants/product";

// Components
import AsyncRitualButton from "../global/AsyncRitualButton";
import PaymentsSection from "./ReceiptPayments";
import PromoCode from "./PromoCode";
import Text from "../Text";

// Services
import intl from "../../services/intl";

// Utils
import { formatTotal } from "../../utils/cart";
import { rem, Color, responsive, rgba, Opacity } from "../../utils/style";
import { trackCheckoutCTAClicked } from "../../utils/tracking/cart";

// Hooks
import useDiscount from "../../hooks/discount/useDiscount";
import { useShipping } from "../../hooks/useShipping";

// Store
import { useSelector, useDispatch } from "react-redux";
import { reserveInput } from "../../store/apply-code/actions";
import couponSelectors from "../../store/coupon/selectors";
import promotionSelectors from "../../store/promotion/selectors";
import cartProductSelectors from "../../store/cart-product/selectors";
import ProductPromotionReceiptMessage from "./ProductPromotionReceiptMessage";
import ProductPromotionReceiptSummary from "./ProductPromotionReceiptSummary";

const RecieptContainer = styled.div.attrs({
  role: "section",
  "aria-labelledby": "receipt-header",
})`
  /* Height of bottom nav + 32 spacing */
  margin-bottom: calc(65px + 32px);

  ${responsive.sm`
    margin-bottom: 24px;
  `}

  ${responsive.md`
    margin-bottom: 32px;
  `}
`;

const Rule = styled.div`
  width: 100%;
  background: #000000;
  opacity: 0.16;
  border-radius: 0px;
  height: 1px;
  margin: 16px 0;

  ${responsive.md`
    margin-bottom: 24px;
  `};
`;

const TopRule = styled(Rule)`
  opacity: 1;
  background: ${Color.ritualBlue};
  height: 2px;

  ${responsive.sm`
    height: 3px;
  `};
`;

const SummarySection = styled.div``;

const SummaryTitle = styled.h2`
  color: ${Color.ritualBlue};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 500;
  margin: 0;

  ${responsive.md`
    font-size: ${rem(20)};
    line-height: ${rem(30)};
  `}
`;

const SummaryDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const SummaryItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;

  ${responsive.md`
    margin-bottom: 8px;
  `};

  &:last-of-type {
    margin-bottom: 0;
  }

  p {
    color: ${(p) => (p.green ? Color.ritualGreen : Color.ritualBlue)};
    font-size: ${rem(14)};
    line-height: ${rem(24)};
    font-weight: 500;
    margin-bottom: 0;

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: ${rem(28)};
    `}
    em {
      font-style: normal;
      font-weight: 300;
    }
  }

  p:last-of-type {
    color: ${(p) =>
      p.green ? Color.ritualGreen : rgba(Color.ritualBlue, Opacity.light)};
    font-weight: 300;
    text-align: right;
  }
`;

export { SummaryItem as ReceiptSummaryItem };

const TotalSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const CheckoutButtonSection = styled.div`
  display: none;

  ${responsive.sm`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  `}
  a {
    margin-top: 8px;
    width: 100%;
    max-width: 285px;

    ${responsive.md`
      margin-top: 16px;
      max-width: 100%;
    `};

    span {
      font-size: 18px;
      line-height: 28px;
    }
  }
`;

const MessageContainer = styled.div`
  margin-bottom: 32px;

  p {
    margin: 0;
    color: ${Color.ritualGreen};
    font-weight: 500;

    margin-top: 8px;
    font-size: ${rem(12)};
    line-height: ${rem(20)};

    ${responsive.md`
      margin-top: 16px;
      font-size: ${rem(14)};
    `}x
    &:first-of-type {
      margin-top: 0;
    }
  }
`;

export { MessageContainer as ReceiptMessageContainer };

const CartReceipt = (props) => {
  function getCouponDurationMessage(coupon) {
    if (!coupon) return "";

    const { duration, durationInMonths } = coupon;

    if (duration === "repeating") {
      return intl.t(
        "cart.receipt.valid-for",
        `Coupon valid only for {duration} months.`,
        { duration: durationInMonths },
      );
    } else if (duration === "once") {
      return intl.t(
        "cart.receipt.code-applies-month-once",
        "Your promo code applies to this month.",
      );
    }
    return "";
  }

  function getPromotionMessage(appliedPromotion, isAddingToSubscription) {
    const promotion = useDiscount(ActionType.Checkout);

    if (!promotion) return null;

    if (!appliedPromotion) {
      return intl.t(
        "cart.receipt.promo-applies-next",
        "A {name} will be applied in the next step",
        { name: promotion.displayName },
      );
    }

    const cart = props.cart;
    const prediscountTotal = formatTotal(cart.subtotal + cart.discountAmount);

    let message = intl.t(
      "cart.receipt.promo-applies-month",
      `Nice! Your {name} applies to this month`,
      { name: promotion.displayName },
    );

    if (!isAddingToSubscription) {
      message += intl.t(
        "cart.receipt.promo-adding",
        `. Future monthly totals will be {total} plus tax`,
        { total: prediscountTotal },
      );
    }

    return message;
  }

  function getCreditRemainingMessage(creditRemaining, giftCardAmountUsed) {
    if (!creditRemaining) return "";

    const credit = formatTotal(creditRemaining);

    if (giftCardAmountUsed > 0) {
      return intl.t(
        "cart.receipt.credit-used",
        `Your gift card has been applied and your credit balance after this transaction is {credit}.`,
        { credit },
      );
    } else {
      return intl.t(
        "cart.receipt.credit-balance",
        `Your credit balance after this transaction is {credit}.`,
        { credit },
      );
    }
  }

  async function handleCheckoutCTAClicked() {
    await trackCheckoutCTAClicked();
    await dispatch(reserveInput());
  }

  let { cart, checkoutUrl, isProcessing, isAddingToSubscription } = props;

  const activeCoupon = useSelector(couponSelectors.activeCoupon);
  const appliedPromotion = useSelector(promotionSelectors.appliedPromotion);
  const activeCartProductQuantity = useSelector(
    cartProductSelectors.activeCartProductQuantity,
  );

  const dispatch = useDispatch();

  const shipping = useShipping();

  const couponDurationMessage = getCouponDurationMessage(activeCoupon);
  const promotionMessage = getPromotionMessage(
    appliedPromotion,
    isAddingToSubscription,
  );
  const creditRemainingMessage = getCreditRemainingMessage(
    cart.creditRemaining,
  );
  const showMessage =
    couponDurationMessage ||
    (promotionMessage && !activeCoupon) ||
    creditRemainingMessage;

  return (
    <>
      <RecieptContainer>
        <SummarySection>
          <SummaryTitle id="receipt-header">
            <Text id="cart.receipt.summary-title" defaultMessage="Summary" />
          </SummaryTitle>
          <TopRule />

          <SummaryDetails>
            <SummaryItem>
              <p>
                <Text
                  id="cart.receipt.product-total"
                  defaultMessage="Product Total"
                />{" "}
                ({activeCartProductQuantity})
              </p>
              <p>
                {formatTotal(
                  cart.prediscountTotal,
                  false /* deduction */,
                  isProcessing,
                )}
              </p>
            </SummaryItem>

            {activeCoupon && (
              <SummaryItem green={true}>
                <p>
                  <Text id="cart.receipt.promo-label" defaultMessage="Promo:" />{" "}
                  <em>{activeCoupon.code}</em>
                </p>
                <p>
                  {formatTotal(
                    cart.discountAmount,
                    true /* deduction */,
                    isProcessing,
                  )}
                </p>
              </SummaryItem>
            )}
            <ProductPromotionReceiptSummary />
            {appliedPromotion && (
              <SummaryItem green={true}>
                <p>{appliedPromotion.displayName}</p>
                <p>
                  {formatTotal(
                    cart.discountAmount,
                    true /* deduction */,
                    isProcessing,
                  )}
                </p>
              </SummaryItem>
            )}

            {!!cart.giftCardAmountUsed && (
              <SummaryItem green={true}>
                <p>
                  <Text
                    id="cart.receipt.gift-card"
                    defaultMessage="Gift Card"
                  />
                </p>
                <p>
                  {formatTotal(
                    cart.giftCardAmountUsed,
                    false /* deduction */,
                    isProcessing,
                  )}
                </p>
              </SummaryItem>
            )}

            {!!cart.creditsUsed && (
              <SummaryItem green={true}>
                <p>
                  <Text
                    id="cart.receipt.account-credit"
                    defaultMessage="Account Credit"
                  />
                </p>
                <p>
                  {formatTotal(
                    cart.creditsUsed,
                    false /* deduction */,
                    isProcessing,
                  )}
                </p>
              </SummaryItem>
            )}

            <SummaryItem data-test-subtotal-message>
              <p>
                <Text id="cart.receipt.subtotal" defaultMessage="Subtotal" />
              </p>

              <p>
                {formatTotal(
                  cart.subtotal,
                  false /* deduction */,
                  isProcessing,
                  true,
                )}
              </p>
            </SummaryItem>

            <SummaryItem data-test-shipping-message>
              <p>
                <Text id="cart.receipt.shipping" defaultMessage="Shipping" />
              </p>
              <p>
                {shipping?.shippingAmount ? (
                  formatTotal(
                    shipping.shippingAmount,
                    false,
                    isProcessing,
                    true,
                  )
                ) : (
                  <Text id="cart.receipt.free" defaultMessage="Free" />
                )}
              </p>
            </SummaryItem>
          </SummaryDetails>
        </SummarySection>

        <Rule />

        <TotalSection>
          {showMessage && (
            <MessageContainer>
              {couponDurationMessage && <p>{couponDurationMessage}</p>}
              {promotionMessage && <p>{promotionMessage}</p>}
              {creditRemainingMessage && <p>{creditRemainingMessage}</p>}
            </MessageContainer>
          )}

          <ProductPromotionReceiptMessage />

          {!isAddingToSubscription && <PromoCode isProcessing={isProcessing} />}
        </TotalSection>

        <CheckoutButtonSection>
          <AsyncRitualButton
            disabled={isProcessing}
            onClick={handleCheckoutCTAClicked}
            href={checkoutUrl}
            target="_self"
          >
            <Text
              id="cart.nav.button-checkout"
              defaultMessage="Proceed to Checkout"
            />
          </AsyncRitualButton>
        </CheckoutButtonSection>

        <PaymentsSection />
      </RecieptContainer>
    </>
  );
};

export default CartReceipt;
