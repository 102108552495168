import classNames from "classnames";

// Components
import Ribbon from "../global/Ribbon";

// Utils
import useBundlePromotion from "../../hooks/discount/useBundlePromotion";
import { ActionType } from "../../constants/product";
import { getPromotionDetails } from "../../utils/promotion";

export default function BundleRibbon(props) {
  const { actionType = ActionType.AddToCart, quantity = 2 } = props;
  const promotion = useBundlePromotion(actionType, quantity);

  if (!promotion) {
    return null;
  }

  const copy = getPromotionDetails(promotion).titleCopy;

  return (
    <Ribbon
      {...props}
      className={classNames(!copy && "hidden", props.className)}
    >
      {copy}
    </Ribbon>
  );
}
