import { Component } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Services
import { navigate } from "../services/navigation";

// Components
import DiscountButton from "./bundle/DiscountButton";

// Utils
import { Color, Font, rem, responsive, Opacity } from "../utils/style";
import {
  getPlanForId,
  getContentfulProductForPlan,
  getProductAttributes,
} from "../utils/planToProduct";
import { getProductForContentfulId } from "../utils/planToProduct";

// Store
import { connect } from "react-redux";
import { addProductToCart } from "../store/cart/actions";

const LinkContainer = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  appearance: none;
  background: none;
  border: none;

  background-color: rgba(242, 241, 245, ${Opacity.light});
  color: ${Color.ritualBlue};

  width: 100%;
  height: 100%;
  padding: 16px 16px 24px 16px;
  border-radius: 8px;

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }
`;

const ProductImageWrapper = styled.div`
  height: 84px;
  width: 84px;
`;

const ProductName = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin: 0;
  margin-bottom: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: center !important;

  font-size: ${rem(14)};
  line-height: ${rem(24)};

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  `}

  em {
    ${Font.dutch};
    font-style: italic;
    display: block;
    text-align: center;
  }
`;

const QuickAddButtonWrapper = styled.div``;

export class QuickAddCard extends Component {
  constructor(props) {
    super(props);

    this.plan = getPlanForId(props.planId);
    if (!this.plan) return;

    this.contentfulProduct = getContentfulProductForPlan(props.planId);
    this.productAttributes = getProductAttributes(props.planId);
    this.productProperties = getProductForContentfulId(
      this.contentfulProduct.contentfulId,
    );
    this.handleCtaClick = this.handleCtaClick.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
  }

  handleCtaClick(e) {
    e.stopPropagation();
    const propertiesToTrack = this.props.location
      ? { location: this.props.location }
      : {};
    this.props.dispatchAddProductToCart(
      { planId: this.plan.id },
      propertiesToTrack,
    );
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  handleCardClick() {
    // link to PDP when clicking on the card (not Cta)
    const { urlSlug } = this.productAttributes;
    navigate(`/products/${urlSlug}`);
  }

  render() {
    const { blueButton, disabled, promotion, isLink } = this.props;

    if (!this.plan) return null;

    const { alternateThumbnail: image, summary } = this.contentfulProduct;
    const headline = this.contentfulProduct.productSubhead;

    return (
      <LinkContainer onClick={this.handleCardClick}>
        <ProductImageWrapper>
          {image && (
            <GatsbyImage
              image={getImage(image)}
              fadeIn={true}
              alt={image.description}
              style={{
                width: "100%",
                height: "100%",
                userSelect: "none",
                userDrag: "none",
                pointerEvents: "none",
                touchCallout: "none",
              }}
            />
          )}
        </ProductImageWrapper>
        <ProductName>
          <em>{headline}</em>
          {summary}
        </ProductName>
        <QuickAddButtonWrapper>
          <DiscountButton
            onClick={this.handleCtaClick}
            price={this.productAttributes.price}
            prefix="Add "
            isLink={isLink}
            className={`short ${blueButton ? "" : "primary-cta"}`}
            disabled={disabled}
            promotion={promotion}
            allowCurrencyPostfix={false}
          />
        </QuickAddButtonWrapper>
      </LinkContainer>
    );
  }
}

export default connect(null, {
  dispatchAddProductToCart: addProductToCart,
})(QuickAddCard);
