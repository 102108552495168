import { ReceiptMessageContainer } from "./Receipt";
import Text from "../Text";

// Data
import { productPromotionTranslationKeysFor } from "../../data/product-promotion/product-promotion";

// Redux
import { useSelector } from "react-redux";
import promotionSelectors from "../../store/promotion/selectors";

function ProductPromotionReceiptMessage() {
  const product = useSelector(
    promotionSelectors.appliedProductPromotionProduct,
  );

  if (!product) {
    return null;
  }

  const translationsKeys = productPromotionTranslationKeysFor(product.sku);

  return (
    <ReceiptMessageContainer>
      <p>
        <Text id={translationsKeys["description-alternative"]} />
      </p>
    </ReceiptMessageContainer>
  );
}

export default ProductPromotionReceiptMessage;
