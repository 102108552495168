import { ReceiptSummaryItem } from "./Receipt";
import Text from "../Text";

// Utils
import { productPromotionPrice } from "../../utils/productPromotion";
import Currency from "../Currency";

// Redux
import { useSelector } from "react-redux";
import promotionSelectors from "../../store/promotion/selectors";

function getPrice(product) {
  const priceDecimal = productPromotionPrice(product.sku);

  return Currency({ value: priceDecimal, round: true });
}

function ProductPromotionReceiptSummary() {
  const product = useSelector(
    promotionSelectors.appliedProductPromotionProduct,
  );

  if (!product) {
    return null;
  }

  return (
    <ReceiptSummaryItem green={true}>
      <p>
        <Text
          id={"product.promotions.free-gift-value"}
          defaultMessage={"Free Gift ({price} Value)"}
          values={{ price: getPrice(product) }}
        />
      </p>
      <p>
        <Text id={"product.promotions.free"} />
      </p>
    </ReceiptSummaryItem>
  );
}

export default ProductPromotionReceiptSummary;
