import styled from "styled-components";

// Constants
import { ActionType } from "../../constants/product";

// Services
import intl from "../../services/intl";

// Utils
import { Color, responsive, rem } from "../../utils/style";
import { Icons } from "../../utils/react-svg";
import { getPromotionDetails } from "../../utils/promotion";

// Hooks
import useBundlePromotion from "../../hooks/discount/useBundlePromotion";

// Components
import Text from "../Text";

const Offer = styled.div`
  width: 100%;
  background-color: ${Color.white};
  padding: 16px 24px;
  margin: 16px 0 0;
  text-align: center;

  ${responsive.md`
    padding: 24px 32px;
    margin-top: 24px;
  `};

  p {
    color: ${Color.ritualBlue};
    font-size: ${rem(14)};
    line-height: ${rem(24)};
    font-weight: 500;
    margin: 0 0 8px;

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: ${rem(28)};
      margin-bottom: 16px;
    `};

    &:last-child {
      font-size: ${rem(12)};
      line-height: ${rem(18)};
      font-weight: 300;
      margin-bottom: 0;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      ${responsive.md`
        font-size: ${rem(14)};
        line-height: ${rem(20)};
      `};

      svg {
        width: 14px;
        height: 11px;
        margin-right: 12px;

        g {
          fill: ${Color.ritualYellow};
        }
      }
    }
  }
`;

export default function BundleOffer(props) {
  const { cartQuantity, isAddingToSubscription } = props;

  const promotion = useBundlePromotion(ActionType.Checkout, cartQuantity);
  if (!promotion) return null;

  const offerText = intl.t(
    "cart.review-order.bundle-offer",
    `Your bundle saves {amount} off your {isAdding, select,
          yes {next}
          other {first}
        } order`,
    {
      amount: getPromotionDetails(promotion).formattedValue,
      isAdding: isAddingToSubscription ? "yes" : "no",
    },
  );

  return (
    <Offer>
      <p>{offerText}</p>
      <p>
        <Icons.CheckRounded />{" "}
        <Text
          id="cart.review-order.bundle-offer-p"
          defaultMessage="Ships together to reduce packaging"
        />
      </p>
    </Offer>
  );
}
