import styled from "styled-components";

// Utils
import metrics from "../../utils/metrics";
import {
  getPlanForId,
  getPlanIdForProductSku,
  getProductAttributes,
} from "../../utils/planToProduct";
import { getPromotionDetails } from "../../utils/promotion";
import { Color, Font, rem, responsive } from "../../utils/style";

// Components
import Container from "../Container";
import MagicLink from "../MagicLink";
import QuickAddCard from "../QuickAddCard";
import Row from "../Row";
import ScrollableOverflow from "../ScrollableOverflow";
import Text from "../Text";
import Highlighter from "../global/Highlighter";
import Circle from "../home/Circle.New";

import { useSelector } from "react-redux";
import { bestEligiblePromotion } from "../../store/promotion/selectors";

const MerchandisedProductSkus = ["EFW60", "EPRE60", "SYN30"];

// Styled Elements
const EmptyCartWrapper = styled.div`
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 52px;
  padding-top: 24px;
  padding-bottom: 56px;
  height: calc(100vh - 52px);

  overflow: hidden;

  ${responsive.sm`
    padding-top: 48px;
  `};

  ${responsive.md`
    margin-top: 64px;
    padding-top: 80px;
    height: calc(100vh - 64px);
  `};
`;

const Column = styled.div.attrs({
  className: "col-12",
})`
  display: flex;
  z-index: 2;

  justify-content: flex-start;
  padding-left: 20px;
  padding-right: 20px;

  ${responsive.sm`
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
  `};
`;

const OveflowColumn = styled(Column).attrs({
  className: "col-12",
})`
  margin-bottom: 16px;

  ${responsive.sm`
    margin-bottom: 24px;
  `};

  ${responsive.md`
    margin-bottom: 48px;
  `};
`;

const QuickAddCardWrapper = styled.div`
  display: inline-block;
  width: 192px;
  margin: 0 8px;

  ${responsive.md`
    width: 214px;
  `};

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

const Heading = styled.div`
  margin-bottom: 32px;

  ${responsive.md`
    margin-bottom: 48px;
  `};

  h1 {
    margin: 0;
    font-size: ${rem(22)};
    line-height: ${rem(32)};
    letter-spacing: -0.2px;
    font-weight: 500;
    color: ${Color.ritualBlue};
    text-align: center !important;

    ${responsive.sm`
      text-align: center;
    `};

    ${responsive.md`
      font-size: ${rem(34)};
      line-height: ${rem(40)};
      letter-spacing: -0.5px;
    `};
  }
`;

const DiscountHeading = styled.div`
  max-width: 500px;

  text-align: left;
  margin-top: 16px;

  ${responsive.sm`
    text-align: center;
  `};

  ${responsive.md`
    margin-top: 16px;
  `};

  span {
    display: inline;
    text-align: left;
    font-weight: 500;
    letter-spacing: 0px;

    font-size: ${rem(18)};
    line-height: ${rem(28)};

    ${responsive.sm`
      text-align: center;
    `};

    ${responsive.md`
      font-size: ${rem(26)};
      line-height: ${rem(36)};
      letter-spacing: -0.3px;
    `}
  }

  .highlighter {
    display: inline-block;
  }
`;

const ShopAllLink = styled.div`
  p {
    margin: 0;
    ${Font.dutch};
    letter-spacing: 0px;
    font-size: ${rem(14)};
    line-height: ${rem(24)};

    ${responsive.md`
      font-size: ${rem(16)};
      line-height: ${rem(26)};
    `}
  }

  a {
    border-bottom: 1px solid ${Color.ritualBlue};
  }
`;

const EmptyCartHeading = function () {
  const promotion = useSelector(bestEligiblePromotion);

  if (promotion) {
    return (
      <Heading>
        <h1>
          <Text id="cart.empty.message" defaultMessage="Your cart is empty." />
        </h1>
        <DiscountHeading>
          <Highlighter hover={false} tight={true} dynamic={true}>
            <span>
              <Text
                id="cart.empty.discount"
                defaultMessage="Save {copy}"
                values={{
                  copy: getPromotionDetails(promotion).formattedValue,
                }}
              />
            </span>
          </Highlighter>{" "}
          <span>
            <Text
              id="cart.empty.discount-ending"
              defaultMessage="on your next order when you add any multivitamin to your
            subscription."
            />
          </span>
        </DiscountHeading>
      </Heading>
    );
  }

  return (
    <Heading>
      <h1>
        <Text id="cart.empty.message" defaultMessage="Your cart is empty." />
        <br />
        <Text
          id="cart.empty.message-second"
          defaultMessage="Fill it with something good."
        />
      </h1>
    </Heading>
  );
};

export default function EmptyCart(props) {
  const merchandisedPlanIds = MerchandisedProductSkus.map((sku) =>
    getPlanIdForProductSku(sku),
  );

  function handleShopAllClick() {
    metrics.track("CTA Clicked", {
      location: "Empty Cart",
      title: "Shop All",
    });
  }

  return (
    <EmptyCartWrapper>
      <Container>
        <Row>
          <Column>
            <EmptyCartHeading {...props} />
          </Column>
        </Row>

        <Row>
          <OveflowColumn>
            <ScrollableOverflow
              centered={false}
              bleedLeft={true}
              bleedRight={true}
            >
              {/* TODO: ohsnapitscolin Get product specific discount data here. */}
              {merchandisedPlanIds.map((planId) => (
                <EmptyCartItem key={planId} planId={planId} />
              ))}
            </ScrollableOverflow>
          </OveflowColumn>
        </Row>

        <Row>
          <Column>
            <ShopAllLink>
              <p>
                <Text
                  id="cart.empty.shop-all-heading"
                  defaultMessage="Looking for something else?"
                />{" "}
                <MagicLink
                  to="/shop/daily-health"
                  title="Shop the full lineup"
                  onClick={handleShopAllClick}
                >
                  <Text
                    id="cart.empty.shop-all-link"
                    defaultMessage="Shop All"
                  />
                </MagicLink>
              </p>
            </ShopAllLink>
          </Column>
        </Row>
      </Container>
      <Circle className="bottom right active" />
    </EmptyCartWrapper>
  );
}

function EmptyCartItem({ planId }) {
  const plan = getPlanForId(planId);
  const promotion = useSelector(bestEligiblePromotion);

  if (!plan.inStock) return null;

  function handleUpsellClick() {
    const product = getProductAttributes(planId);

    metrics.track("CTA Clicked", {
      location: "Empty Cart",
      title: product.name,
    });
  }

  return (
    <QuickAddCardWrapper>
      <QuickAddCard
        planId={planId}
        promotion={promotion}
        onClick={handleUpsellClick}
        location="Empty Cart"
      />
    </QuickAddCardWrapper>
  );
}
