import styled from "styled-components";

// Utils
import { Color, responsive, rgba, Opacity } from "../../utils/style";
import { Icons } from "../../utils/react-svg";
import { PaymentIcons } from "../../utils/svg";
// Services
import intl from "../../services/intl";

// Components
import Text from "../Text";
import { MagicSVGImage } from "../MagicSVGImage";

// Styled Elements
const PaymentsContainer = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${responsive.lg`
    margin-top: 40px;
  `};

  p {
    color: ${rgba(Color.ritualBlue, Opacity.light)};
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.67px;
    line-height: 18px;
    text-align: center;
  }

  p.pay-by {
    margin-bottom: 8px;

    ${responsive.lg`
      margin-bottom: 0px;
      width: 50px;
      text-align: right;
      margin-right: 16px;
    `}
  }
`;

const PayByContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  order: 2;

  ${responsive.lg`
    order: 2;
    flex-direction: row;
    width: calc(100% + 80px);
    max-width: 340px;
    margin: auto;
    margin-left: -30px;
    align-items: baseline;
  `}

  ${responsive.lg`
    margin: auto;
  `}
`;

const IconsContainer = styled.div`
  width: 100%;
  max-width: 405px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    width: calc((100% / 7) - 3px);

    ${responsive.lg`
      width: calc((100% / 7) - 4px);
    `}

    img {
      width: 100%;
    }
  }
`;

const SecurePayments = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  order: 1;
  margin-bottom: 24px;

  ${responsive.lg`
    margin-bottom: 40px;
    order: 1;

    &:first-of-type {
      margin-top: -24px;
    }
  `}

  svg {
    width: 7px;
    height: auto;
    margin-right: 5px;

    ${responsive.md`
        width: 10px;
        height: 12px;
    `};

    ${responsive.lg`
      margin-right: 10px;
    `}
  }

  p {
    color: ${Color.ritualBlue};
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 12px;
    text-align: center;
    margin-bottom: 0px;
    padding-top: 2px;

    ${responsive.md`
      padding-top: 4px;
    `};

    ${responsive.lg`
      font-size: 12px;
    `}
  }
`;

const ReceiptPayments = () => {
  const payments = intl.payments;

  return (
    <PaymentsContainer>
      <SecurePayments>
        <Icons.Lock />
        <p>
          <Text
            id="cart.receipt-payments.note"
            defaultMessage="Payments are processed securely."
          />
        </p>
      </SecurePayments>

      <PayByContainer>
        <p className="pay-by">
          <Text id="cart.receipt-payments.pay-by" defaultMessage="PAY BY" />
        </p>

        <IconsContainer>
          {payments.applepay && (
            <div>
              <MagicSVGImage
                width={50}
                height={32}
                alt={"ApplePay"}
                src={PaymentIcons.ApplePay}
              />
            </div>
          )}
          {payments.googlepay && (
            <div>
              <MagicSVGImage
                width={50}
                height={32}
                alt={"GooglePay"}
                src={PaymentIcons.GooglePay}
              />
            </div>
          )}
          {payments.paypal && (
            <div>
              <MagicSVGImage
                width={50}
                height={32}
                alt={"PayPal"}
                src={PaymentIcons.PayPal}
              />
            </div>
          )}
          {payments.stripe && (
            <>
              <div>
                <MagicSVGImage
                  width={50}
                  height={32}
                  alt={"Visa"}
                  src={PaymentIcons.Visa}
                />
              </div>
              <div>
                <MagicSVGImage
                  width={50}
                  height={32}
                  alt={"MasterCard"}
                  src={PaymentIcons.MasterCard}
                />
              </div>
              <div>
                <MagicSVGImage
                  width={50}
                  height={32}
                  alt={"Amex"}
                  src={PaymentIcons.Amex}
                />
              </div>
              <div>
                <MagicSVGImage
                  width={50}
                  height={32}
                  alt={"Discover"}
                  src={PaymentIcons.Discover}
                />
              </div>
            </>
          )}
        </IconsContainer>
      </PayByContainer>
    </PaymentsContainer>
  );
};

export default ReceiptPayments;
